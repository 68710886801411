@font-face {
  font-family: 'BTLFontThin';
  src: local('BTLFontThin'), url(./fonts/GTEestiProDisplay-Thin.woff) format('woff');
}

@font-face {
  font-family: 'BTLFontRegularItalic';
  src: local('BTLFontRegularItalic'),
    url(./fonts/GTEestiProDisplay-RegularItalic.woff) format('woff');
}

@font-face {
  font-family: 'BTLFontRegular';
  src: local('BTLFontRegular'), url(./fonts/GTEestiProDisplay-Regular.woff) format('woff');
}

@font-face {
  font-family: 'BTLFontMedium';
  src: local('BTLFontMedium'), url(./fonts/GTEestiDisplay-Medium.woff) format('woff');
}

body {
  background-color: rgb(240, 242, 245);
}

body #root {
  margin: 0px;
  color: rgba(0, 0, 0, 0.65);
}
